<template>
  <div class="first">
    <div class="flex_between bbe9s">
      <div class="title f-left p10">基检结果列表</div>
    </div>
    <!-- 任务查找与筛选 -->
    <div class="mt10">
      <div class="block">
        <div class="flex_warp pb20" style="overflow: inherit !important">
          <!-- 方案名称 -->
          <div class="blocks2 flex_start flex_items">
            <span class="mr20">结果名称</span>
            <el-input
              placeholder="请输入"
              v-model="resultName"
              clearable
              class="littleBox w-200"
            >
            </el-input>
          </div>
          <!-- 方案类型 -->
          <div class="blocks2 flex_start flex_items">
            <span class="mr20">方案类型</span>
            <el-select
              v-model="resultType"
              placeholder="方案类型"
              :clearable="true"
              filterable
              class="littleBox w-200"
            >
              <el-option
                v-for="(item, index) in resultTypes"
                :key="index"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <!-- 筛选 -->
          <el-button
            type="primary"
            size="small"
            plain
            class="mr5 mt10"
            @click="clearSearch"
            >重置</el-button
          >
          <el-button
            type="primary"
            size="small"
            plain
            class="mr5 mt10"
            @click="shaixuan"
            >查询</el-button
          >
          <el-button
            type="primary"
            size="small"
            plain
            class="mr5 mt10 m-l-100"
            @click="addResult"
            >新增结果</el-button
          >
        </div>
      </div>
    </div>
    <div>
      <avue-crud
        :option="option"
        :data="resultData"
        @selection-change="handleSelect"
      >
        <template slot-scope="scope" slot="title">
          <div class="two">
            {{ scope.row.title }}
          </div>
        </template>
        <template slot-scope="{ row, type, size }" slot="menu" class="w-200">
          <!-- <el-button :size="size" :type="type" @click="edit(row)"
            >编辑</el-button
          > -->
          <el-button :size="size" :type="type" @click="detail(row)"
            >详情</el-button
          >
        </template>
      </avue-crud>
      <!-- 页码 -->
      <div class="flex_start mt10 flex_items ml15">
        <span class="demonstration mr20">
          总共
          <span class="f-red">{{ count }}</span
          >条数据,当前 <span class="f-red">{{ resultData.length }}</span
          >条
        </span>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="20"
          :total="count"
          layout="prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>

    <!-- 弹框 -->
    <div v-if="isdiag">
      <el-dialog
        :visible.sync="dialogTableVisible"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        width="55%"
        @open="diagopen()"
        @close="diagclose()"
        :key="keyNum"
      >
        <AddFormula
          v-if="formulaName == 'addFormula'"
          :currentPage.sync="currentPage"
          @getDataAgain="getDataAgain"
        ></AddFormula>
        <EditFormula
          v-if="formulaName == 'editFormula'"
          :currentPage.sync="currentPage"
          @getDataAgain="getDataAgain"
        ></EditFormula>
        <FormulaDet
          v-if="formulaName == 'formulaDet'"
          :currentPage.sync="currentPage"
          @getDataAgain="getDataAgain"
        ></FormulaDet>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// import assign from "@/components/Assign.vue";
export default {
  data() {
    return {
      isdiag: false,
      keyNum: 1,
      resultName: "",
      resultType: "",
      resultTypes: [
        {
          id: 1,
          title: "aa"
        },
        {
          id: 2,
          title: "bb"
        }
      ],
      box: {
        formulaType: "",
        resultName: "",
        radio1: "",
        radio2: "",
        radio3: "",
        checkbox1: [],
        radio4: "",
        radio5: "",
        reminder: ""
      },
      formulaTypes: [],
      resultName: "",
      create_time: "",
      resultData: [],
      multiple: [],
      option: {
        border: true,
        size: "medium",
        menu: true,
        selection: false,
        width: "90%",
        page: false,
        align: "left",
        dateBtn: false,
        dateDefault: false,
        excelBtn: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        columnBtn: false, //列显隐按钮
        refreshBtn: false, //刷新按钮
        column: [
          {
            label: "基检ID",
            prop: "id",
            width: "70px"
          },
          {
            label: "编号",
            prop: "number",
            width: "150px"
          },
          {
            label: "创建时间",
            prop: "creation_time",
            width: "200px"
          },
          {
            label: "基检结果名称",
            prop: "title",
            slot: true
          },
          {
            label: "问题类型",
            prop: "title1",
            width: "118px"
          },
          {
            label: "提醒事项",
            prop: "reminders"
          }
        ]
      },
      locations: [],
      startTimes: [],
      fixRecords: [],
      conditions: [],
      checkRecords: [],
      wallWaters: [],
      // 任务状态
      tasks: [],
      items: [],
      // 分页数据
      currentPage: 1,
      resultData: [],
      count: 0,
      // 弹框
      dialogTableVisible: false,
      formulaName: "addFormula",
      task: 0,
      adminUsername: null,
      problems: []
    };
  },
  mounted() {
    // this.clienton = sessionStorage.getItem("admin");
    this.getLists();
    this.getproblems(241);
  },
  methods: {
    // 列表
    getLists() {
      this.$loading(this.util.opload);
      this.util
        .get(this.HOST + "/Diagnostic/list", {
          page: this.currentPage,
          limit: 20,
          goods_category_id: this.resultType,
          title: this.resultName
        })
        .then(res => {
          if (res.code == 200) {
            this.count = res.data.count;
            this.resultData = res.data.data;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90
            });
          }
        });
    },
    getproblems(city) {
      //获取问题
      if (city) {
        this.util
          .get(this.HOST + "/Common/programme", {
            city_id: city
          })
          .then(res => {
            if (res.code == 200) {
              this.resultTypes = res.data;
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "error",
                duration: 1200,
                offset: 90
              });
            }
          });
      }
    },
    getDiagnostic() {
      //获取基检列表
      this.util.get(this.HOST + "/Diagnostic/diagnostic", {}).then(res => {
        if (res.code == 200) {
          this.checkRecords = res.data.hollowing;
          this.fixRecords = res.data.maintenance;
          this.wallWaters = res.data.moisture;
          this.locations = res.data.position;
          this.conditions = res.data.present;
          this.startTimes = res.data.time;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90
          });
        }
      });
    },
    addResult() {
      this.formulaName = "addFormula";
      // this.dialogTableVisible = true;
      this.$store.commit("setFormulaDialog", true);
    },
    edit(row) {
      this.$store.commit("setFormula", row);
      // this.dialogTableVisible = true;
      this.$store.commit("setFormulaDialog", true);
      this.formulaName = "editFormula";
    },
    ban(row) {},
    detail(row) {
      this.$store.commit("setFormula", row);
      // this.dialogTableVisible = true;
      this.$store.commit("setFormulaDialog", true);
      this.formulaName = "formulaDet";
    },
    clearSearch() {
      this.resultType = "";
      this.resultName = "";
      this.shaixuan();
    },
    // 刷新重新获取数据
    getDataAgain(currentPage) {
      this.keyNum++;
      if (currentPage) {
        this.currentPage = currentPage;
      }
      this.resultData = [];
      if (currentPage) {
        this.currentPage = currentPage;
      }
      this.$loading(this.util.opload);
      this.util
        .get(this.HOST + "/Diagnostic/list", {
          page: this.currentPage,
          limit: 20,
          goods_category_id: this.resultType,
          title: this.resultName
        })
        .then(res => {
          if (res.code == 200) {
            this.count = res.data.count;
            this.resultData = res.data.data;
            this.$loading().close();
            this.getLists();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90
            });
          }
        });
    },
    // 任务类型
    gettasks() {
      this.util.get(this.HOST + "/Common/service", {}).then(res => {
        if (res.code == 200) {
          this.tasks = res.data;
          //   this.getclient();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90
          });
        }
      });
    },

    // 分页
    handleCurrentChange(val) {
      this.$loading(this.util.opload);
      this.resultData = [];
      this.util
        .get(this.HOST + "/Diagnostic/list", {
          page: this.currentPage,
          limit: 20,
          goods_category_id: this.resultType,
          title: this.resultName
        })
        .then(res => {
          if (res.code == 200) {
            this.count = res.data.count;
            this.resultData = res.data.data;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90
            });
          }
        });
    },
    diagopen() {
      this.keyNum++;
      // this.isdiag = true;
      // this.dialogTableVisible = true;
      this.$store.commit("setFormulaDialog", true);
    },
    diagclose() {
      this.keyNum++;
      // this.isdiag = true;
      // this.dialogTableVisible = true;
      // this.box = {};
      this.$store.commit("setFormulaDialog", false);
    },
    shaixuan() {
      this.$loading(this.util.opload);
      this.resultData = [];
      this.currentPage = 1;
      this.util
        .get(this.HOST + "/Diagnostic/list", {
          page: this.currentPage,
          limit: 20,
          goods_category_id: this.resultType,
          title: this.resultName
        })
        .then(res => {
          if (res.code == 200) {
            this.count = res.data.count;
            this.resultData = res.data.data;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90
            });
          }
        });
    },
    // 选择数据
    handleSelect(selection) {
      this.multiple = [];
      if (selection.length) {
        selection.forEach(item => {
          this.multiple = [...this.multiple, item.id];
        });
      }
    },
    exportExcel() {}
  },
  computed: {
    // 判断弹出框
    getStoreItem() {
      this.dialogTableVisible = this.$store.state.dialogFormula;
      this.isdiag = this.$store.state.dialogFormula;
    },
    // 判断处理成功
    getStoreTask() {
      // if (this.$store.state.task == 1) {
      // this.resultData = [];
      // this.getLists("fen");
      // this.shaixuan()
      // }
    },
    getworks() {
      this.works = this.$store.state.works;
    }
  },
  watch: {
    getStoreItem() {},
    getStoreTask() {}
  },
  components: {
    AddFormula: () => import("@/components/addFormula.vue"),
    EditFormula: () => import("@/components/editFormula.vue"),
    FormulaDet: () => import("@/components/formulaDet.vue")
  }
};
</script>
<style scoped>
.first {
  margin: 0 auto;
  background: white;
  /* padding: 10px 30px; */
  padding: 15px 0 0 30px;
  border-radius: 8px;
}
.el-main {
  padding: 10px;
}
.title {
  font-weight: 400;
  color: #1f2f3d;
  font-size: 18px;
  background: none;
  width: 93%;
}
.blocks2 {
  margin-right: 20px;
  margin-top: 10px;
}
.el-input {
  width: auto;
}
li {
  list-style-type: none;
}
.el-dialog {
  width: 85%;
}
.datePicker {
  width: 256px;
  height: 32px !important;
  line-height: 32px !important;
}
.littleBox /deep/ input {
  height: 32px !important;
  line-height: 32px !important;
}
div /deep/ .avue-crud__menu {
  display: none;
}
.blocks2 {
  margin-right: 20px;
  margin-top: 10px;
}
.two {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
